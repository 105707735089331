<div class="sliders">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Anwesenheitsaufnahme">
      <app-form></app-form>
    </mat-tab>
    <mat-tab label="Veranstaltungsprogramm">
      <app-schedule></app-schedule>
    </mat-tab>
    <mat-tab *ngIf="localStoreSet()" routerLink="/list" routerLinkActive label="Liste">
      <app-list></app-list>
    </mat-tab>
  </mat-tab-group>
</div>

