import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {
  }

  localStoreSet(): boolean {
    return localStorage.getItem('PSV_List') === 'active';
  }
}
