<div class="container">
  <p>Datum: {{today}}</p>
  <p>Aktuell eingetragene Personen: {{participantCount$ | async}}</p>

  <div [hidden]="submitted">
    <h1>Corona Digitalzettel</h1>
    <form (ngSubmit)="submit()" #participantForm="ngForm">
      <div>
        <h4>Bitte auswaehlen:</h4>
        <mat-radio-group required [value]="'Nicht'" (change)="setStatus($event)" class="button-container" aria-label="Select an option">
          <mat-radio-button value="Getestet">Getestet</mat-radio-button>
          <mat-radio-button value="Genesen">Genesen</mat-radio-button>
          <mat-radio-button value="Geimpft">Geimpft</mat-radio-button>
          <mat-radio-button value="Nicht">Weder noch</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="name-container">
        <div class="form-group">
          <label for="firstname">Vorname</label>
          <input type="text" class="form-control" id="firstname"
                 required
                 [(ngModel)]="participant.firstname" name="firstname"
                 #firstname="ngModel">
          <div [hidden]="firstname.valid || firstname.pristine"
               class="alert alert-danger">
            Bitte Vornamen angeben.
          </div>
        </div>

        <div class="form-group">
          <label for="lastname">Nachname</label>
          <input type="text" class="form-control" id="lastname"
                 required
                 [(ngModel)]="participant.lastname" name="lastname"
                 #lastname="ngModel">
          <div [hidden]="lastname.valid || lastname.pristine"
               class="alert alert-danger">
            Bitte Nachnamen angeben.
          </div>
        </div>
      </div>

      <div class="info-container">
        <div class="form-group">
          <label for="mobile">Telefon</label>
          <input type="text" class="form-control" id="mobile"
                 required
                 [(ngModel)]="participant.mobile" name="mobile"
                 #mobile="ngModel">
          <div [hidden]="mobile.valid || mobile.pristine"
               class="alert alert-danger">
            Bitte Telefonnummer angeben.
          </div>
        </div>
      </div>

      <div class="adress-container">
        <div class="form-group">
          <label for="street">Straße</label>
          <input type="text" class="form-control" id="street"
                 required
                 [(ngModel)]="address.street" name="street"
                 #street="ngModel">
          <div [hidden]="street.valid || street.pristine"
               class="alert alert-danger">
            Bitte Straße angeben.
          </div>
        </div>

        <div class="form-group">
          <label for="town">Stadt</label>
          <input type="text" class="form-control" id="town"
                 required
                 [(ngModel)]="address.town" name="town"
                 #town="ngModel">
          <div [hidden]="town.valid || town.pristine"
               class="alert alert-danger">
            Bitte Stadt angeben.
          </div>
        </div>

        <div class="form-group">
          <label for="zip">Postleitzahl</label>
          <input type="text" class="form-control" id="zip"
                 required
                 [(ngModel)]="address.zip" name="zip"
                 #zip="ngModel">
          <div [hidden]="zip.valid || zip.pristine"
               class="alert alert-danger">
            Bitte Postleitzahl angeben.
          </div>
        </div>
      </div>


      <button type="submit" class="btn btn-success" [disabled]="!participantForm.form.valid">Speichern</button>
    </form>
  </div>

  <div [hidden]="!submitted">
    <h2>Sie wurden wie folgt eingetragen:</h2>
    <div class="row">
      <div class="col-xs-3">Status:</div>
      <div class="col-xs-9">{{ participant.status}}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">Name:</div>
      <div class="col-xs-9">{{ participant.firstname + ' ' + participant.lastname}}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">Telefon:</div>
      <div class="col-xs-9">{{ participant.mobile}}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">Ankunftszeit:</div>
      <div class="col-xs-9">{{ participant.time}}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">Adresse:</div>
      <div class="col-xs-9">{{ participant.address.street}} in {{participant.address.zip}} {{participant.address.town}}</div>
    </div>
  </div>
</div>
