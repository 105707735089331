import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  participantsList$: Observable<any>;
  collection: AngularFirestoreCollection<Participant>;

  constructor(private asf: AngularFirestore) {
    this.collection = this.asf.collection('participant');
    this.participantsList$ = this.collection.valueChanges().pipe(
      map((res) => {
        const participantsByDate = [];
        res.forEach((participant) => {
          const date = participant.time.split(',')[0];
          const dateEntry = participantsByDate.find(entry => entry.date === date);
          if (dateEntry) {
            dateEntry.participants.push(participant);
          } else {
            participantsByDate.push({date, participants: [participant]});
          }
        });

        participantsByDate.sort((a, b) => {
          const aConverted = this.convertToCommonDateFormat(a.date);
          const bConverted = this.convertToCommonDateFormat(b.date);

          return Date.parse(bConverted) - Date.parse(aConverted);
        });

        return participantsByDate;
      })
    );
  }

  ngOnInit() {}

  convertToCommonDateFormat(date: string): string {
    const dateArray = date.split('.');
    // mm/dd/yyyy
    return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
  }

}
