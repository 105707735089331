import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

@Injectable(
  {providedIn: 'root'}
)
export class ListGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const psvList = localStorage.getItem('PSV_List');
    return psvList === 'active';
  }
}
