// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDPyC-6AkwZMylHAlQ6aO0z2dnR7uGwDbI',
    authDomain: 'sf-psv-anwesenheitsliste.firebaseapp.com',
    databaseURL: 'https://sf-psv-anwesenheitsliste.firebaseio.com',
    projectId: 'sf-psv-anwesenheitsliste',
    storageBucket: 'sf-psv-anwesenheitsliste.appspot.com',
    messagingSenderId: '576610099824',
    appId: '1:576610099824:web:163594001cfa6198634ab5'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
