<div class="container pb-8">
  <h3>Liste eingetragene Personen</h3>

  <mat-accordion *ngIf="participantsList$ | async as participantsList">
    <mat-expansion-panel *ngFor="let date of participantsList">
      <mat-expansion-panel-header>
        <mat-panel-title>Veranstaltungstag {{date.date}}</mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <li *ngFor="let participant of date.participants">
          <div class="row" *ngIf="participant.status">
            <div class="col-xs-3">Status:</div>
            <div class="col-xs-9">{{ participant.status}}</div>
          </div>
          <div class="row">
            <div class="col-xs-3">Name:</div>
            <div class="col-xs-9">{{ participant.firstname + ' ' + participant.lastname}}</div>
          </div>
          <div class="row">
            <div class="col-xs-3">Telefon:</div>
            <div class="col-xs-9">{{ participant.mobile}}</div>
          </div>
          <div class="row">
            <div class="col-xs-3">Ankunftszeit:</div>
            <div class="col-xs-9">{{ participant.time}}</div>
          </div>
          <div class="row" *ngIf="participant.address">
            <div class="col-xs-3">Adresse:</div>
            <div class="col-xs-9">{{ participant.address.street}} in {{participant.address.zip}} {{participant.address.town}}</div>
          </div>
        </li>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</div>
