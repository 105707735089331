import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import {ListGuard} from './guards/list.guard';

const routes: Routes = [
  {
    path: 'list',
    component: ListComponent,
    canActivate: [ListGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
