import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  today = new Date().toLocaleDateString('de-DE');

  address: Address = {
    town: '',
    street: '',
    zip: '',
  };

  participant: Participant = {
    status: 'Nicht',
    firstname: '',
    lastname: '',
    mobile: '',
    time: '',
    address: this.address,
  };
  participantCount$: Observable<number>;
  collection: AngularFirestoreCollection<Participant>;

  submitted = false;

  constructor(private asf: AngularFirestore) {
    this.collection = this.asf.collection('participant');
    this.participantCount$ = this.collection.valueChanges().pipe(
      map((res) => {
        const participantsToday = [];
        res.forEach((participant) => {
          const date = participant.time.split(',')[0];
          if (date === this.today) {
            participantsToday.push(participant);
          }
        });

        return participantsToday.length;
      })
    );
  }

  ngOnInit() {}

  submit() {
    const date = new Date();
    this.submitted = true;
    this.participant.time = `${date.toLocaleDateString(
      'de-DE'
    )}, ${date.toLocaleTimeString('de-DE')}`;

    this.collection.add(this.participant);
  }

  setStatus(status) {
    this.participant.status = status.value;
  }
}
